import { loadable } from "../../utils/loadable";

const FeatureView = loadable(() =>
  import(
    /* webpackChunkName: "Views__VatAccountingGroupManagement" */ "./FeatureView"
  )
);

export const vatAccountingGroupManagement = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "vat-accounting-group/create",
      element: <FeatureView name="VatAccountingGroupCreate" />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "vat-accounting-group/:vatAccountingGroupId/edit",
      element: <FeatureView name="VatAccountingGroupEdit" />
    }
  }
];
