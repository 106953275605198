import { loadable } from "../../utils/loadable";

const VatAccountingGroup = loadable(() =>
  import(/* webpackChunkName: "VatAccountingGroup" */ "./VatAccountingGroup")
);

export const vatAccountingGroupView = [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "vat-accounting-group",
      element: <VatAccountingGroup />
    }
  }
];
