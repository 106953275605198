import React, { createContext, useRef, useState } from "react";
import { useSnackbar } from "@onefront/react-sdk";
import { useComet } from "./use-comet";
import { useUpdatr } from "./use-updatr";
import { useDebounce } from "./use-debounce";
import { useGraphql } from "./use-graphql";

export const CqrsContext = createContext();

const GET_COMMANDS = `
  query GetCommands(
    $cursor: String = ""
  ) {
    comet: cqrsComet(target: commands, cursor: $cursor) {
      target
      items
    }
  }
`;

const GET_RESPONSES = `
  query GetResponses(
    $cursor: String = ""
  ) {
    comet: cqrsComet(target: responses, cursor: $cursor) {
      target
      items
    }
  }
 `;

const ISSUE_COMMAND = `
 mutation IssueCommand (
   $scope: String!, 
   $name: String!, 
   $payload: json!
 ) {
   cmd: cqrs_issue_command(
     args: {
       scope: $scope,
       name: $name,
       payload: $payload
     }
   ) {
     cmd_id
     cmd_name
     cmd_scope
     tenant_id
     payload
     created_at
   }
 }
`;

const combine = (command, response) => ({
  cmd_id: command.cmd_id,
  cmd_name: command.cmd_name,
  cmd_scope: command.cmd_scope,
  issued_at: command.created_at,
  updated_at: response ? response.created_at : command.created_at,
  tenant_id: command.tenant_id,
  payload: command.payload,
  response: response ? response.response : null,
  error: response ? response.error : null,
  status: response ? response.status : null
});

export const CqrsProvider = ({ component, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { query } = useGraphql();
  const { bump: bumpUpdatr } = useUpdatr(0);
  const debounceResponses = useDebounce(0);
  const responsesMapRef = useRef({});

  const [cache, setCache] = useState({ items: [], map: {} });
  const cleanCache = () =>
    setCache((cache) => {
      const ids = commands.cursorRef.current;
      const items = [];
      const map = {};

      cache.items.forEach((item) => {
        if (ids.includes(item.cmd_id)) return;
        items.push(item);
        map[item.cmd_id] = item;
      });

      return { items, map };
    });

  const commands = useComet({
    query: GET_COMMANDS,
    cursorField: "cmd_id",
    // Once new commands come in we set a delayed updated as so
    // to debounce immediate updates.
    //
    // Only the first update is immediate as so to quickly apply
    // command that have still not being executed by the backend.
    onChange: () => bumpUpdatr((v) => (v ? null : 0)).then(cleanCache)
  });

  const responses = useComet({
    query: GET_RESPONSES,
    cursorField: "rsp_id",
    onChange: (nextItems) =>
      debounceResponses(() => {
        responsesMapRef.current = nextItems.reduceRight(
          (acc, curr) => ({
            ...acc,
            [curr.cmd_id]: curr
          }),
          {}
        );
        bumpUpdatr();
      })
  });

  const issueCommand = (name, scope, payload, options = {}) =>
    new Promise(async (resolve, reject) => {
      const pollInterval = options.pollInterval || 500;
      const onSuccess = options.onSuccess || null;
      const onError = options.onError || null;
      const onStatus = options.onStatus || null;
      const onComplete = options.onComplete || null;
      const useCache = options.useExperimentalCache;
      const shouldComplete = options.shouldComplete || false;
      const shouldReject = options.shouldReject || false;
      const shouldFeedback =
        typeof options.shouldFeedback === "boolean"
          ? options.shouldFeedback
          : true;
      const snackbarOptions = options.snackbarOptions || {};

      // EXPERIMENTAL:
      // Optimistic update pre-issuing the command
      //
      // The main issue here is that there is no ID, so post-command
      // reconciliation is going to be a hassle.
      //
      // One possible way to solve this problem is to issue PushID
      // from the client by exposing a specific API through SQL
      // function + Hasura.
      //
      // NOTE: right now it doesn't work at all with CREATE action
      //       it triggers an error and haven't yet started debugging
      //       that stuff out.
      const wishfulUpdate = {
        cmd_id: Date.now(),
        cmd_scope: scope,
        cmd_name: name,
        created_at: new Date(),
        payload,
        response: null,
        error: null,
        status: null
      };

      useCache &&
        setCache((cache) => ({
          items: [...cache.items, wishfulUpdate],
          map: cache.map
        }));

      // Send out the command syncronously.
      // Once the command comes back, we have a full command payload.
      const command = (
        await query(ISSUE_COMMAND, {
          name,
          scope,
          payload
        })
      ).data.cmd[0];

      // Optimistic update of the full command
      if (useCache) {
        setCache((cache) => {
          const items = [
            ...cache.items.filter(($) => $.cmd_id !== wishfulUpdate.cmd_id),
            command
          ];
          return {
            items,
            map: items.reduce((a, c) => ({ ...a, [c.cmd_id]: c }), {})
          };
        });
      }

      // Normal cache of the newly sent command.
      // Even if the long-polling is fast, this is still going to
      // make it feel near-real-time even in case we use a big
      // debouncing timeout.
      if (!useCache) {
        setCache((cache) => ({
          items: [...cache.items, command],
          map: { ...cache.map, [command.cmd_id]: command }
        }));
      }

      const loop = () => {
        const response = responsesMapRef.current[command.cmd_id];
        if (!response) {
          setTimeout(loop, pollInterval);
          return;
        }

        const data = combine(command, response);

        if (response.status) {
          onStatus && onStatus(data);
          setTimeout(loop, pollInterval);
          return;
        }

        if (response.response) {
          onSuccess && onSuccess(data);
          onComplete && onComplete(data);
          resolve(data);
        }

        if (response.error) {
          // TODO: may need to apply try/catch to handle different error shapes
          // TODO: define a standard and run WARNINGS if it is not managed
          let errorMsg = "";
          try {
            errorMsg = Array.isArray(response.error.errors)
              ? response.error.errors[0].message
              : response.error.message;

            if (typeof errorMsg !== "string") {
              console.warn("Unexpected error format", response.error);
              errorMsg = JSON.stringify(errorMsg);
            }
          } catch (err) {
            console.warn(err);
            console.warn(response.error);
            errorMsg = "Unexpected error format";
          }

          const error = new Error(errorMsg);
          error.originalError = response.error;
          error.commandData = data;

          // Automatic UI feedback
          shouldFeedback &&
            enqueueSnackbar(errorMsg, {
              variant: "error",
              autoHideDuration: 3000,
              ...snackbarOptions
            });

          // Run callbacks
          onError && onError(error, data);
          onComplete && onComplete(data);

          // Rejection is optional
          shouldReject ? reject(error) : resolve(data);
        }
      };

      loop();

      // Resolve the issueCommand without awaiting for the complete response
      // (useful to get the `cmd_id` right away!)
      if (shouldComplete === false) {
        resolve(combine(command, {}));
      }
    });

  // Combine the hardened commands with the in-memory cache
  // the idea here is to remove duplicates
  const commandIds = commands.cursorsRef.current;
  const combinedCommands = [
    ...commands.itemsRef.current,
    ...cache.items.filter((item) => !commandIds.includes(item.cmd_id))
  ]
    .map((command) => combine(command, responsesMapRef.current[command.cmd_id]))
    .reverse();

  const ctx = {
    cache,
    commands: combinedCommands,
    issueCommand,
    stats: {
      commands: {
        total: commands.itemsRef.current.length,
        cursor: commands.cursorRef.current
      },
      responses: {
        total: responses.itemsRef.current.length,
        cursor: responses.cursorRef.current
      }
    }
  };

  return (
    <CqrsContext.Provider value={ctx}>
      {React.createElement(component, props)}
    </CqrsContext.Provider>
  );
};
