import pkg from "../package.json";

/**
 * OneFront SDK
 * ============
 *
 * This package provides most of the Services that you may need
 * in a OnePlatform Application.
 *
 * You write simple React Components and interact with the SDK
 * using modern hooks API.
 *
 * The SDK guides you into dividing you App into isolated Features
 * that you will glue together using ForrestJS APIs.
 *
 * REACT-STARTED
 * Swap comment on line 19/20 to use React Starter
 *
 * NOTE: You must create a company for your TSID:
 * https://appdev.teamsystemdigital.com/
 * (it takes up to 20 minutes to work... keep reloading...)
 */
//import onefront from "@onefront/react-sdk";
import onefront from "@drift/oneplatfront"; // will become @onefront/react-starter

/**
 * Import the App's Features
 * =========================
 *
 * A Feature is an isolated portion of business logic that
 * interacts with the App's Services
 */

import { cqrs } from "./services/cqrs";
import { dashboard } from "./features/dashboard";
import { tradeItemsView } from "./features/trade-items-view";
import { tradeItemsManagement } from "./features/trade-items-management";
import { vatAccountingGroupView } from "./features/vat-accounting-group-view";
import { vatAccountingGroupManagement } from "./features/vat-accounting-group-management";

// @TODO: evaluate wether to apply envalid or similar validation
//        or no default in production and hard crash
const APP_ID = process.env.REACT_APP_APP_ID || "demo";

console.log(`AppId: ${APP_ID}; Version: ${pkg.version}`);

/**
 * Configure and Run the App
 * =========================
 *
 * The boot process returns a Promise that you can handle
 * to figure out whether all was good or not.
 */
onefront
  .run({
    // trace: "compact",
    settings: {
      one: {
        auth: {
          token: {
            refresh:
              (process.env.REACT_APP_TOKEN_FORCE_REFRESH || "yes") === "yes"
          }
        },
        login: {
          target: {
            url: "loginApp",
            params: ["mode=redirect", `appId=${APP_ID}`]
          }
        },
        box: {
          app: { id: "dcbe1292-007e-49c1-8e42-1c4fed662f15" },
          module: { name: "scm::trade-items::app", version: pkg.version }
        }
      },
      tradeItems: {
        basePath: "trade-items",
        urn: APP_ID
      },
      vatAccountingGroup: {
        basePath: "vat-accounting-group",
        urn: APP_ID
      },
      invoicing: {
        urn: "scm::invoicing::app"
      }
    },
    services: [cqrs],
    features: [
      dashboard,
      tradeItemsView,
      tradeItemsManagement,
      vatAccountingGroupView,
      vatAccountingGroupManagement,
      // FIX: set default (static) language to english
      // TODO: fixme
      {
        target: "$ONE_I18N_OVERRIDE_CONFIG",
        handler: (cfg) => ({
          ...cfg,
          load: "languageOnly",
          ns: ["react-sdk", "scm"]
        })
      }
    ]
  })
  .catch(console.error);
