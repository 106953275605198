import { loadable } from "../../utils/loadable";

const TradeItemsView = loadable(() =>
  import(/* webpackChunkName: "TradeItemsView" */ "./TradeItemsView")
);

export const tradeItemsView = [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "trade-items",
      element: <TradeItemsView />
    }
  }
];
