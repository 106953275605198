import { loadable } from "../../utils/loadable";

const FeatureView = loadable(() =>
  import(/* webpackChunkName: "Views__TradeItemManagement" */ "./FeatureView")
);

export const tradeItemsManagement = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/trade-items/create",
      element: <FeatureView name="TradeItemsCreate" />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/trade-items/:tradeItemId/edit",
      element: <FeatureView name="TradeItemsEdit" />
    }
  }
];
