import { useEmbedDrawerSelect } from "@onefront/react-sdk";

import Button from "@vapor/react-material/Button";
import VaporPage from "@vapor/react-custom/VaporPage";

const DevStuff = () => {
  const foobar = useEmbedDrawerSelect(`demo://trade-items`, {
    // title: "Select Trade Item",
    width: "560px",
    onDisclose: (data) => console.log(data)
  });

  return (
    <VaporPage title="DevStuff">
      <VaporPage.Section>
        <Button onClick={foobar.open} variant="contained">
          Select Trade Item
        </Button>
        {foobar.el}
      </VaporPage.Section>
    </VaporPage>
  );
};

export default DevStuff;
